import React, { Component } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import { logoutUser } from "../../actions/authActions";
import {
  faBackward,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-collapse";
import { Link } from "react-router-dom";
import usState from "../../utils/state.json";
import { confirmAlert } from "react-confirm-alert";
class DrugUtilization extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "ndc",
        text: "NDC",
        className: "ndc",
        align: "left",
        sortable: true,
      },
      {
        key: "utilization_type",
        text: "Utilization Type",
        className: "utilization_type",
        align: "left",
        sortable: true,
      },
      {
        key: "year",
        text: "Year",
        className: "year",
        align: "left",
        sortable: true,
      },
      {
        key: "quarter",
        text: "Quarter",
        className: "quarter",
        align: "left",
        sortable: true,
      },
      {
        key: "product_name",
        text: "Product Name",
        className: "product_name",
        align: "left",
        sortable: true,
      },
      {
        key: "state",
        text: "State",
        className: "state",
        align: "left",
        sortable: true,
      },
      {
        key: "units_reimbursed",
        text: "Unit Reimbursed",
        className: "units_reimbursed",
        align: "left",
        sortable: true,
      },
      {
        key: "number_of_prescriptions",
        text: "Number of Prescription",
        className: "number_of_prescriptions",
        align: "left",
        sortable: true,
      },
      {
        key: "total_amount_reimbursed",
        text: "Amount Reimbursed",
        className: "total_amount_reimbursed",
        align: "left",
        // sortable: true,
      },
      {
        key: "medicaid_amount_reimbursed",
        text: "Medicaid Reimbursed",
        className: "medicaid_amount_reimbursed",
        align: "left",
        // sortable: true,
      },
      {
        key: "non_medicaid_amount_reimbursed",
        text: "Non-Medicaid Reimbursed",
        className: "non_medicaid_amount_reimbursed",
        align: "left",
        // sortable: true,
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Drug List",
      no_data_text: "No record found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "total_amount_reimbursed", order: "desc" },
      },
      currentRecord: {},
      state: undefined,
      loading: false,
      isFilterOpen: true,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    // this.getData()
  }

  getData() {
    this.setState({ loading: true });
    let filterData = {};
    filterData.conditions = [
      {
        resource: "t",
        property: "state",
        value: this.state.state,
        operator: "=",
      },
    ];
    filterData.offset =
      (parseInt(this.state.filterData.page_number) - 1) *
      this.state.filterData.page_size;
    filterData.limit = this.state.filterData.page_size;
    filterData.sorts = [
      {
        property: this.state.filterData.sort_order.column,
        order: this.state.filterData.sort_order.order,
      },
    ];
    axios
      .post(
        "https://data.medicaid.gov/api/1/datastore/query/d890d3a9-6b00-43fd-8b31-fcba4c8e2909/0",
        filterData
      )
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.results.length; i++) {
          let each = res.data.results[i];
          if (each.ndc !== "") {
            each.effective_date = each.effective_date
              ? moment(each.effective_date).format("L")
              : "";
            each.as_of_date = each.as_of_date
              ? moment(each.as_of_date).format("L")
              : "";
            each.total_amount_reimbursed = each.total_amount_reimbursed
              ? Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(each.total_amount_reimbursed)
              : "";
            each.medicaid_amount_reimbursed = each.medicaid_amount_reimbursed
              ? Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(each.medicaid_amount_reimbursed)
              : "";
            each.non_medicaid_amount_reimbursed = each.non_medicaid_amount_reimbursed
              ? Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(each.non_medicaid_amount_reimbursed)
              : "";
            each.number_of_prescriptions = parseInt(
              each.number_of_prescriptions
            );
            each.units_reimbursed = parseInt(each.units_reimbursed);
            result.push(each);
          }
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        state: undefined,
        filterData: {
          page_number: 1,
          limit: 20,
          sort_order: { column: "total_amount_reimbursed", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      utilization_type: "Utilization Type",
      state: "State",
      ndc: "NDC",
      package_size: "Package Size",
      year: "Year",
      quarter: "Quarter",
      suppression_used: "Suppression Used",
      product_name: "Product Name",
      units_reimbursed: "Unit Reimburesed",
      number_of_prescriptions: "No. of Prescriptions",
      total_amount_reimbursed: "Amount Reimburesed",
      medicaid_amount_reimbursed: "Medicaid Reimburesed",
      non_medicaid_amount_reimbursed: "Non Medicaid Reimburesed",
    };
    let filterData = {};
    filterData.conditions = [
      {
        resource: "t",
        property: "state",
        value: this.state.state,
        operator: "=",
      },
    ];
    filterData.offset = 0;
    filterData.limit = 9999;
    filterData.sorts = [
      {
        property: this.state.filterData.sort_order.column,
        order: this.state.filterData.sort_order.order,
      },
    ];
    axios
      .post(
        "https://data.medicaid.gov/api/1/datastore/query/d890d3a9-6b00-43fd-8b31-fcba4c8e2909/0",
        filterData
      )
      .then((res) => {
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false });
        for (let i in res.data.results) {
          let record = res.data.results[i];
          delete record.labeler_code;
          delete record.product_code;
          delete record.labeler_code;
          record.effective_date = record.effective_date
            ? moment(record.effective_date).format("L")
            : "";
          record.as_of_date = record.as_of_date
            ? moment(record.as_of_date).format("L")
            : "";
          // record.total_amount_reimbursed = record.total_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(record.total_amount_reimbursed) : ""
          // record.medicaid_amount_reimbursed = record.medicaid_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(record.medicaid_amount_reimbursed) : ""
          // record.non_medicaid_amount_reimbursed = record.non_medicaid_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(record.non_medicaid_amount_reimbursed) : ""
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
  };

  convertToCSV(objArray) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <h1 className="mt-2 text-primary">Drug Utilization by State</h1>
            <p className="help-text font-weight-bold h6">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ color: "red" }}
              />
              &nbsp;Data updates automatically when available.
            </p>
            {this.props.auth.user.superadmin && (
              <div>
                Source:{" "}
                <a href="https://data.medicaid.gov/api/1/datastore/query/d890d3a9-6b00-43fd-8b31-fcba4c8e2909/0">
                  https://data.medicaid.gov/api/1/datastore/query/d890d3a9-6b00-43fd-8b31-fcba4c8e2909/0
                </a>
              </div>
            )}
            <button className="btn btn-warning">
              <FontAwesomeIcon icon={faBackward} />
              <Link to="/dashboard"> Go to Dashboard</Link>
            </button>
            <br></br>
            <br></br>
            <div>
              {/* <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultChecked={this.state.isFilterOpen} onClick={this.toggleChange} id="isFilterOpen" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Show Filter
                                </label>
                            </div> */}
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="state">State</label>
                      <select
                        name="state"
                        onClick={this.onChangeSelect}
                        className="form-control"
                        id="state"
                      >
                        <option value="" selected="">
                          Please select
                        </option>
                        {Object.keys(usState).map((each, i) => (
                          <option
                            key={i}
                            value={each}
                            selected={this.state.state === each}
                          >
                            {usState[each]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>
            <p style={{ fontSize: "20px" }}>
              <b>MCOU</b> - Managed Care Organization Utilization
              <br></br>
              <b>FFSU</b> - Fee For Service Utilization
            </p>

            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps, { logoutUser })(DrugUtilization);
