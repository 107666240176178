import React from "react";
import DatePicker from "react-datepicker";

const Costplus = (props) => {
  const { errors } = props;
  return (
    <>
      <div className="card">
        <div className="card-header" id="headingThree">
          <h5 className="mb-0">
            <button
              type="button"
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#Costplus"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Costplus
            </button>
          </h5>
        </div>
        <div
          id="Costplus"
          className="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="bonarx">Exp Date</label>
              </div>
              <div className="col-md-9">
                <DatePicker
                  selected={props.expiration_costplus}
                  onChange={(date) =>
                    props.setDate(date, "expiration_costplus")
                  }
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="costplus_subscriptionId">Subscription Id</label>
              </div>
              <div className="col-md-9">
                <input
                  onChange={props.onChange}
                  value={props.costplus_subscriptionId}
                  id="costplus_subscriptionId"
                  type="text"
                  error={errors.costplus_subscriptionId}
                  className={"form-control"}
                />
                <span className="text-danger">
                  {errors.costplus_subscriptionId}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <label htmlFor="costplus_domain">Subdomain</label>
              </div>
              <div className="col-md-9">
                <input
                  onChange={props.onChange}
                  value={props.costplus_domain}
                  id="costplus_domain"
                  type="text"
                  error={errors.costplus_domain}
                  className={"form-control"}
                />
                <span className="text-danger">{errors.costplus_domain}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Costplus;
