export const availableSolutions = [
  {
    name: "Growth Plan",
    price: "89.99",
    class: "Growth",
    type: "plan",
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/GrowthRx Plan (1).png",
    content:
      "TabulaRx, RxReach, BonaRx, AlereRx, Access to Marketing Store, 1 Free Social Media Asset/mo",
    width: "250px",
    saveImg: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/save20.png",
  },
  {
    name: "Essential Plan",
    price: "179.99",
    class: "Essential",
    type: "plan",
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/EssentialRx Plan.png",
    content:
      "XPedicareRx-24, RxCreator ($49.99), TabulaRx, Access to Marketing Store, 1 Free Flyer per month, VIP Technical help.",
    width: "250px",
    saveImg: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/save25.png",
  },
  {
    name: "Premium Plan",
    price: "219.99",
    class: "Premium",
    type: "plan",
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/PremiumRx Plan.png",
    content:
      "XPedicareRx-24, RxCreator ($89.99), TabulaRx, RxReach, BonaRx, AlereRx, Access to Marketing Store, 1 Free Flyer per month, VIP Technical help",
    width: "250px",
    saveImg: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/save30.png",
  },
  {
    name: "XPedicareRx-24",
    price: "89.99",
    class: "premium",
    tooltip: "24/7 Access",
    subId: "xpedicare24_subscriptionId",
    expiry: "expiration_xpedicare24",
    essential: true,
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/XpedicareRx24_n.png",
    content:
      "Telemedicine, Including Compounded and Lifestyle Medications. HIPAA Compliant, Custom Landing Page",
    width: "210px",
    borderColor: "#44e0cc",
    subName: "xpedicare24",
  },
  {
    name: "XpedicareRx-24 Plus",
    price: "129.99",
    class: "premium",
    tooltip: "24/7 Access",
    subId: "xpedicare24_plus_subscriptionId",
    expiry: "expiration_xpedicare24_plus",
    essential: true,
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/XPedicarerx plus_n.png",
    content:
      "Telemedicine, Including Compounded and Lifestyle Medications. HIPAA Compliant, Custom Landing Page, Plus Social Media Content Management",
    width: "230px",
    borderColor: "#44e0cc",
    subName: "xpedicare24_plus",
  },
  {
    name: "TabulaRx",
    price: "69.99",
    class: "growth",
    tooltip: "Data Analytics/Compliance",
    subId: "subscriptionId",
    expiry: "expiration_tabula",
    free: true,
    essential: true,
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/TabulaRx.png",
    content:
      "Prescribing, Dispensing, Provider Data, Physician Marketing, CS Compliance",
    width: "160px",
    borderColor: "#377BB5",
    subName: "tabulaRx",
  },
  {
    name: "RxCreator",
    price: "49.99",
    class: "premium",
    tooltip: "Digital Marketing",
    subId: "rxcreator_subscriptionId",
    expiry: "expiration_rxcreator",
    essential: true,
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/RxCreator.png",
    content:
      "Social Media Content Management, 8-16 posts per month, Video Shorts and Snippets",
    width: "170px",
    borderColor: "#377BB5",
    subName: "rxcreator",
  },

  {
    name: "Marketing",
    class: "addon",
    tooltip: "Basic Access",
    subId: "marketing_subscriptionId",
    expiry: "expiration_marketing",
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/Marketing logo.png",
    content: "Access the 1000's of Print and Digital Marketing Assets",
    width: "160px",
    borderColor: "#ffd500",
    subName: "marketing",
  },
  {
    name: "Marketing Plus",
    price: "349.99",
    class: "addon",
    tooltip: "Basic Access and much more",
    subId: "marketingp_subscriptionId",
    expiry: "expiration_marketingp",
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/Marketing plus.png",
    content:
      "Al a Carte Access to all of our digital solutions, including RxChatbot and CRM, SEO, Reputation Management, Campaign Pro and More",
    width: "200px",
    borderColor: "#ffd500",
    subName: "marketingp",
  },
  {
    name: "CostPlus",
    class: "addon",
    price: "199.99",
    tooltip: "Basic Access",
    subId: "costplus_subscriptionId",
    expiry: "expiration_costplus",
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/CostplusRx.png",
    content:
      "Boost sales and patient loyalty with our CostPlus program and Custom Portal and Widgets.",
    width: "200px",
    borderColor: "#ff5c00",
    subName: "costplus",
  },
  {
    name: "CostPlus Xtra",
    class: "addon",
    price: "299.99",
    tooltip: "Basic Access",
    subId: "costplus_xtra_subscriptionId",
    expiry: "expiration_costplus_xtra",
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/costplus xtra_n.png",
    content:
      "Boost sales and patient loyalty with our CostPlus program, Custom Portal, RxChatbot, RxMinder-Lite for a better patient experience.",
    width: "200px",
    borderColor: "#ff5c00",
    subName: "costplus_xtra",
  },

  {
    name: "DoorstepRx",
    price: "39.99",
    class: "addon",
    tooltip: "Delivery Platform",
    subId: "doorstep_subscriptionId",
    expiry: "expiration_doorstep",
    free: true,
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/DoorstepRx.png",
    content:
      "Delivery Platform including routing and optimization, unlimited drivers and vehicles, digital record keeping including signature and picture.",
    width: "200px",
    borderColor: "#44e0cc",
    subName: "doorstepRx",
  },
  {
    name: "RxReach",
    price: "19.99",
    class: "growth",
    subId: "rxreach_subscriptionId",
    expiry: "expiration_rxreach",
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/RxReach.png",
    content:
      "Custom communication including SMS, MMS, Voice, Fax and Email Broadcast. Free IVR number.",
    width: "140px",
    borderColor: "#44e0cc",
    subName: "rxReach",
  },

  {
    name: "RxMinder",
    price: "89.99",
    class: "addon",
    subId: "rxminder_subscriptionId",
    expiry: "expiration_rxminder",
    free: true,
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/RxMinder.png",
    content:
      "Custom Patient Education and Engagement Tool includes 100's of templates. Create your audio in any language.",
    width: "160px",
    borderColor: "#44e0cc",
    subName: "rxminder",
  },
  {
    name: "RxPodcast",
    price: "89.99",
    class: "addon",
    subId: "rxpodcast_subscriptionId",
    expiry: "expiration_rxpodcast",
    hidePrice: true,
    image:
      "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/RxPodcast_logo.png",
    content:
      "Differentiate yourself from the competition by having your own podcast to engage and educate.",
    width: "180px",
    borderColor: "#44e0cc",
    subName: "rxPodcast",
  },
  {
    name: "BonaRx",
    price: "9.99",
    class: "growth",
    subId: "bonarx_subscriptionId",
    expiry: "expiration_bonarx",
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/BonaRx.png",
    content:
      "Custom medication savings cards. Get access to local dispensing data and generate revenue.",
    width: "130px",
    borderColor: "#44e0cc",
    subName: "bonaRx",
  },
  {
    name: "AlereRx",
    price: "9.99",
    class: "growth",
    subId: "alererx_subscriptionId",
    expiry: "expiration_alererx",
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/AlereRx.png",
    content:
      "Telehealth includes unlimited TelaDoc 24/7, Aetna Disc, Dental, Vision, Lab and Imaging for family of Five for $24.99/mo.",
    width: "140px",
    borderColor: "#44e0cc",
    subName: "alererx",
  },

  // {
  //   name: "Compliance",
  //   price: "89.99",
  //   class: "addon",
  //   tooltip: "Regulatory",
  //   subId: "compliance_subscriptionId",
  //   expiry: "expiration_compliance",
  //   image:
  //     "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/XPedicareRx.png",
  //   content:
  //     "RxChatbot, Reputation Management, Campaign Pro and Local SEO solution",
  //   width: "200px",
  // },
  {
    name: "Audit",
    price: "89.99",
    class: "addon",
    subId: "audit_subscriptionId",
    expiry: "expiration_audit",
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/RxConnexion.png",
    content:
      "Our Experts can help you reduce Audit risks by Analyzing data from your pharmacy.",
    width: "200px",
    subName: "audit",
  },
  {
    name: "MentoRx",
    price: "899.99",
    class: "addon",
    subId: "mentorx_subscriptionId",
    expiry: "expiration_mentorx",
    image: "https://d3l9g5ay50k79j.cloudfront.net/choose-plan/RxConnexion.png",
    content:
      "Connect with our Experts for a one-on-one consultation to help you grow your business.",
    width: "200px",
    subName: "mentorx",
  },
];
