import React from "react";
import classnames from "classnames";
import usState from "../../utils/state.json";
import isEmpty from "is-empty";
import axios from "axios";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreditCardInput from "react-credit-card-input";
import PulseLoader from "react-spinners/PulseLoader";
import queryString from "query-string";
import { logoutUser } from "../../actions/authActions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { availableSolutions } from "./../../utils/constants";
const rxpodcastPrice = {
  Basic: 89.99,
  Plus: 149.99,
  Pro: 199.99,
};
const mockData = {
  cardNumber: "4242 4242 4242 4242",
  cvv: "1234",
  expiry: "12/24",
  name: "sanchit srivastava",
  street: "test",
  city: "tset",
  state: "FL",
  zip: "32701",
};
class CardInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      cardNumber: "",
      expiry: "",
      cvv: "",
      _id: "",
      current_points: 0,
      records: [],
      cardError: true,
      loading: false,
      name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      termscondition: false,
      solutions: [],
      plan: "",
      finalPrice: 0,
      rxpodcastPlan: undefined,
      clinic: false,
      showClinic: false,
      // ...mockData,
    };
  }

  componentDidMount() {
    let finalPrice = 0;

    if (queryString.parse(window.location.search).solution) {
      queryString
        .parse(window.location.search)
        .solution.split("|")

        .map((eachSol) => {
          let match = availableSolutions.find(
            (eachAvailableSol) => eachAvailableSol.name === eachSol
          );
          if (match) {
            let price = match.price;
            if (
              eachSol === "RxPodcast" &&
              queryString.parse(window.location.search).rxpodcastPlan
            ) {
              price =
                rxpodcastPrice[
                  queryString.parse(window.location.search).rxpodcastPlan
                ];
            }
            finalPrice = finalPrice + parseFloat(price);
          }
        });

      this.setState({
        solutions: queryString
          .parse(window.location.search)
          .solution.split("|")
          .map((eachSol) =>
            eachSol === "xpedicare24" ? "XPedicareRx24" : eachSol
          )
          .filter((eachSol) => !eachSol.includes("rxpodcastPlan")),
        finalPrice: parseFloat(finalPrice).toFixed(2),
      });
    }
    if (queryString.parse(window.location.search).plan) {
      finalPrice =
        queryString.parse(window.location.search).plan === "Growth Plan"
          ? "89.99"
          : queryString.parse(window.location.search).plan === "Premium Plan"
          ? "219.99"
          : queryString.parse(window.location.search).plan === "Essential Plan"
          ? "179.99"
          : "";

      this.setState({
        plan: queryString.parse(window.location.search).plan,
        finalPrice: parseFloat(finalPrice).toFixed(2),
      });
    }
    if (queryString.parse(window.location.search).customLink === "true") {
      this.setState({ clinic: true });
    }
    if (queryString.parse(window.location.search).showClinic === "true") {
      this.setState({ showClinic: true });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSave = (e) => {
    e.preventDefault();

    let { errors } = this.state;
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.street === "") {
      errors.street = "This field is required";
    } else {
      delete errors.street;
    }
    if (this.state.city === "") {
      errors.city = "This field is required";
    } else {
      delete errors.city;
    }
    if (this.state.state === "") {
      errors.state = "This field is required";
    } else {
      delete errors.state;
    }
    if (this.state.zip === "") {
      errors.zip = "This field is required";
    } else {
      delete errors.zip;
    }
    if (this.state.termscondition === false) {
      errors.termscondition = "This field is required";
    } else {
      delete errors.termscondition;
    }
    if (this.state.cardError === false && isEmpty(errors)) {
      const newCard = {
        email: this.props.auth.user.final_user,
        cardNumber: this.state.cardNumber,
        expiry: this.state.expiry,
        cvv: this.state.cvv,
        amount: this.state.clinic
          ? parseFloat(this.state.finalPrice) + 39.99
          : this.state.finalPrice,
        name: this.state.name,
        street: this.state.street,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        plan: this.state.plan,
        solutions: this.state.solutions,
        clinic: this.state.clinic,
      };
      confirmAlert({
        title: "Confirmation Required",
        message:
          "To Authorize your card, we will deduct $1 from your card and will refund it within 72 hours.",
        buttons: [
          {
            label: "Ok",
            onClick: () => this.postCall(newCard),
          },
        ],
      });
    } else {
      if (this.state.cardError) {
        alert("Invalid card detail");
      }
      this.setState({ errors });
    }
  };

  postCall = (newCard) => {
    this.setState({
      loading: true,
    });
    axios
      .post("/subscribe/subscribe", newCard)
      .then((res) => {
        this.setState({
          loading: false,
          errors: {},
          cardNumber: "",
          expiry: "",
          name: "",
          street: "",
          city: "",
          state: "",
          zip: "",
        });
        if (newCard.solutions.includes("XPedicareRx24")) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="xp24">
                  <h1>Important</h1>
                  <p>
                    Thank you for subscribing to XpedicareRx24. Your pharmacy
                    needs to be vetted to be able to receive electronic
                    prescriptions automatically. In the next 1-2 hours you will
                    receive an electronic test prescription which contains a
                    "Verification Code" on the face of the prescription. Next,
                    go to the "User" tab at the bottom right corner of the
                    XPedicareRx homepage and enter the "Verification Code" to
                    submit. Once this step is completed you will see an "Active"
                    status on the subscription tab for XPedicarerRx 24.<br></br>{" "}
                    We strongly suggest that you schedule a 15-minute launch
                    call with our experts to review best practices for the
                    XPedicareRx24 for a better user experience.<br></br> Please
                    click the link here to schedule your launch call today:{" "}
                    <a href="https://calendly.com/rxconnexion/30min?month=2024-04">
                      https://calendly.com/rxconnexion/30min?month=2024-04
                    </a>
                    <br />
                    <br />
                    Thank you for being a valuable client.
                    <br />
                    If you have any questions please call us at 888-442-8348{" "}
                    <br />
                    RxConnexion Team
                  </p>
                  <button
                    onClick={() => {
                      this.props.logoutUser();
                      onClose();
                    }}
                    className="btn btn-primary"
                  >
                    I understood
                  </button>
                </div>
              );
            },
          });
        } else {
          confirmAlert({
            title: "Subscription Successfull",
            message: "Please logout & login to continue.",
            buttons: [
              {
                label: "Continue",
                onClick: () => this.props.logoutUser(),
              },
            ],
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  handleCardNumberChange = (e) => {
    this.setState({
      cardNumber: e.target.value,
      cardError: false,
    });
  };

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  handleCardExpiryChange = (e) => {
    this.setState({
      expiry: e.target.value,
      cardError: false,
    });
  };

  handleCardCVCChange = (e) => {
    this.setState({
      cvv: e.target.value,
      cardError: false,
    });
  };

  setError = () => {
    this.setState({
      cardError: true,
    });
  };

  onCheckboxChange = () => {
    this.setState({
      clinic: !this.state.clinic,
    });
  };

  render() {
    const { errors, plan, solutions, clinic, showClinic } = this.state;
    let finalPrice = parseFloat(this.state.finalPrice);
    if (clinic) {
      finalPrice = (finalPrice + 39.99).toFixed(2);
    }

    const iFrame =
      '<div className="AuthorizeNetSeal"> <script type="text/javascript" language="javascript">var ANS_customer_id="308dab4c-21ef-414b-a2ca-db0d7684fa3d";</script> <script type="text/javascript" language="javascript" src="https://verify.authorize.net:443/anetseal/seal.js" ></script> </div>';
    return (
      <>
        <header data-bs-theme="dark" style={{ paddingLeft: "0" }}>
          <div className="navbar navbar-dark bg-dark shadow-sm">
            <div className="container">
              <a
                href="/landing"
                className="navbar-brand d-flex align-items-center"
              >
                <img
                  src={
                    "https://tabula.psgarx.com/static/media/RxConnexion.80ee8ffc.png"
                  }
                  alt="Logo"
                  width={300}
                />
              </a>
              <button
                type="button"
                className="btn btn-dark me-2 right"
                onClick={() => this.props.logoutUser()}
              >
                Logout ({this.props.auth.user.name})
              </button>
            </div>
          </div>
        </header>
        <main
          style={{ paddingLeft: "0", minHeight: "calc(-195px + 100vh)" }}
          className="choose-plan-initial container"
        >
          <div className="d-flex" id="wrapper">
            {this.state.loading && (
              <div className="loading-wrapper">
                <PulseLoader color="#007bff" loading={true} size={20} />
              </div>
            )}
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <form
                  noValidate
                  onSubmit={this.onUpload}
                  id="add-points"
                  style={{ clear: "both" }}
                >
                  <div className="card">
                    <>
                      <div className="card-header">
                        {solutions.length ? (
                          <h5 className="fw-light" style={{ fontSize: "32px" }}>
                            You have selected to Subscribe to the following
                            Solutions: <br></br>
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              {solutions.join(", ")}
                            </span>
                          </h5>
                        ) : (
                          ""
                        )}
                        {plan !== "" ? (
                          <h5 className="fw-light" style={{ fontSize: "32px" }}>
                            You have selected to Subscribe to the following
                            Plan: <br></br>
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              {plan}
                            </span>
                          </h5>
                        ) : (
                          ""
                        )}
                        <h5
                          style={{
                            fontWeight: "bold",
                            fontSize: "26px",
                          }}
                        >
                          <b>Enter Card Information </b>
                        </h5>
                        <h5 style={{ fontWeight: "bold" }}>
                          ${finalPrice} per month - Auto-renewal
                        </h5>
                        {/* {solutions.includes("XPedicareRx24") && showClinic ? (
                          <>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="1"
                                id="clinic"
                                checked={this.state.clinic}
                                onChange={this.onCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                for="clinic"
                                style={{ marginTop: "0", marginLeft: "5px" }}
                              >
                                <h5
                                  style={{
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  Check here to get your custom landing page for
                                  your website
                                </h5>
                              </label>
                            </div>
                          </>
                        ) : (
                          ""
                        )} */}

                        <h5>
                          Questions? Call us{" "}
                          <a href="tel:888-442-8348">888-442-8348 Ext-1</a>
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <h5 htmlFor="name">Card Details</h5>
                          </div>
                          <div className="col-md-12">
                            <CreditCardInput
                              onError={() => this.setError()}
                              cardNumberInputProps={{
                                value: this.state.cardNumber,
                                onChange: this.handleCardNumberChange,
                              }}
                              cardExpiryInputProps={{
                                value: this.state.expiry,
                                onChange: this.handleCardExpiryChange,
                              }}
                              cardCVCInputProps={{
                                value: this.state.cvv,
                                onChange: this.handleCardCVCChange,
                              }}
                              fieldClassName="input"
                              customTextLabels={{
                                cardNumberPlaceholder: "0000 0000 0000 0000",
                              }}
                            />
                          </div>
                          <div className="col-md-2">
                            <iframe
                              style={{
                                height: "95px",
                                borderWidth: 0,
                              }}
                              title={"badge"}
                              srcDoc={iFrame}
                            />
                          </div>
                          <div className="col-md-2">
                            <a
                              href="//www.securitymetrics.com/site_certificate?id=1993241&tk=f04ccba354c624538cc9b56b9153a5ec"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="https://www.securitymetrics.com/portal/app/ngsm/assets/img/BlueContent_Credit_Card_Safe_White_Sqr.png"
                                alt="SecurityMetrics card safe certification logo"
                                height={100}
                              />
                            </a>
                          </div>
                          <br></br>
                          <br></br>
                          <div className="col-md-12">
                            <h5 htmlFor="name">Billing Address</h5>
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="name">Name</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.name}
                              id="name"
                              type="text"
                              error={errors.name}
                              className={classnames("form-control", {
                                invalid: errors.name,
                              })}
                            />
                            <span className="text-danger">{errors.name}</span>
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="street">Address Line 1</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.street}
                              id="street"
                              type="text"
                              error={errors.street}
                              className={classnames("form-control", {
                                invalid: errors.street,
                              })}
                            />
                            <span className="text-danger">{errors.street}</span>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmlFor="city">City</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.city}
                              id="city"
                              type="text"
                              error={errors.city}
                              className={classnames("form-control", {
                                invalid: errors.city,
                              })}
                            />
                            <span className="text-danger">{errors.city}</span>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmlFor="state">State</label>
                            <select
                              name="state"
                              onChange={this.onChange}
                              className="form-control"
                              id="state"
                            >
                              <option value="" selected="">
                                Please Select
                              </option>
                              {Object.keys(usState).map((each, i) => (
                                <option
                                  key={i}
                                  value={each}
                                  selected={this.state.state === each}
                                >
                                  {usState[each]}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmsuperadminlFor="zip">Zip</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.zip}
                              id="zip"
                              type="text"
                              error={errors.zip}
                              className={classnames("form-control", {
                                invalid: errors.zip,
                              })}
                            />
                            <span className="text-danger">{errors.zip}</span>
                          </div>
                          <div className="col-md-12 col-12">
                            <br></br>
                            <input
                              type="checkbox"
                              className="form-check float-left"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                              Checked={this.state.termscondition}
                              onClick={this.toggleChange}
                              id="termscondition"
                            />
                            <b>
                              <p style={{ fontSize: "20px" }}>
                                By clicking, I confirm that I have read,
                                understood and agree with the{" "}
                                <a
                                  href="https://www.rxconnexion.com/terms"
                                  target="_blank"
                                >
                                  terms and conditions{" "}
                                </a>{" "}
                              </p>
                            </b>
                            <span className="text-danger">{errors.zip}</span>
                          </div>
                        </div>

                        <button
                          type="button"
                          onClick={() => this.props.history.goBack()}
                          className="btn btn-secondary mr-2"
                        >
                          Back
                        </button>
                        <button
                          form="add-points"
                          type="submit"
                          onClick={this.onSave}
                          className="btn btn-primary"
                        >
                          Pay Now
                        </button>
                      </div>
                    </>
                  </div>
                </form>
              </div>
            </div>
            <ToastContainer />
          </div>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addUser, logoutUser })(
  withRouter(CardInfo)
);
