import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import queryString from "query-string";
import TermCondition from "../partials/TermCondition";
import $ from "jquery";
import PulseLoader from "react-spinners/PulseLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import { availableSolutions as solutions } from "./../../utils/constants";
import rxl from "../../utils/rxl.png";

class ChoosePlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPlan: "",
      selectedSolution: [],
      finalPrice: 0,
      userInfo: {},
      inTrial: [],
      termArr: [],
      rxpodcastPlan: undefined,
      currentPlan: undefined,
      custom_url: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.getUserInfo();
    this.markLogin();
    if (queryString.parse(window.location.search).subPlan) {
      this.setState({
        rxpodcastPlan: queryString.parse(window.location.search).subPlan,
      });
    }
  }

  markLogin() {
    const newUser = {
      id: this.props.auth.user.id,
      login_flow: false,
    };
    axios
      .post("/api/user-update", newUser)
      .then((res) => {})
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  getUserInfo = () => {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    // this.setState({ loading: true });
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        let { inTrial } = this.state;
        if (res.data.records[0].tabulaRx) {
          inTrial.push("TabulaRx");
        }
        if (res.data.records[0].rxminder) {
          inTrial.push("RxMinder");
        }
        if (res.data.records[0].doorstepRx) {
          inTrial.push("DoorstepRx");
        }
        this.setState({
          userInfo: res.data.records[0],
          inTrial,
        });
        this.setState({ loading: false });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  choosePlanRxPodcast = (plan) => {
    confirmAlert({
      title: "Awesome!",
      message: `You have selected ${plan} Plan`,
      buttons: [
        {
          label: "Back",
          onClick: () => $("#rxpodcast-plan-modal").modal("hide"),
        },
        {
          label: "Continue",
          onClick: () => {
            $("#rxpodcast-plan-modal").modal("hide");
            this.props.history.push(
              `/card-info?solution=RxPodcast&rxpodcastPlan=${plan}`
            );
          },
        },
      ],
      overlayClassName: "confirm-plan",
    });
  };

  navigateTo = () => {
    this.props.history.push(
      `/card-info?solution=RxPodcast&rxpodcastPlan=${this.state.rxpodcastPlan}`
    );
  };

  linkTo = (each) => {
    if (each.type === "plan") {
      this.props.history.push(`/card-info?plan=${each.name}`);
    } else {
      if (each.name === "RxPodcast") {
        $("#rxpodcast-plan-modal").modal("show");
      } else {
        this.props.history.push(`/card-info?solution=${each.name}`);
      }
    }
  };

  isSubscribed = (plan) => {
    let { userInfo } = this.state;
    if (userInfo[plan.subId] !== undefined && userInfo[plan.subId] !== "") {
      return true;
    }
    if (plan.type === "plan") {
      if (userInfo.plan === "Growth" && plan.class === "Growth") {
        return true;
      }
      if (
        userInfo.plan === "Essential" &&
        (plan.class === "Growth" || plan.class === "Essential")
      ) {
        return true;
      }
      if (
        userInfo.plan === "Premium" &&
        (plan.class === "Growth" ||
          plan.class === "Essential" ||
          plan.class === "Premium")
      ) {
        return true;
      }
    }
    return false;
  };

  getBackground = (plan) => {
    console.log(plan.name);
    if (plan.type === "plan") {
      return `url("https://d3l9g5ay50k79j.cloudfront.net/choose-plan/medical+texture_for+bundle.png")`;
    }
    return `url("https://rxreachanalytis.s3.amazonaws.com/logo/RxConnexion_Login_Artwork.png")`;
  };

  render() {
    return (
      <>
        <header data-bs-theme="dark" style={{ paddingLeft: "0" }}>
          <div className="navbar navbar-dark bg-dark shadow-sm">
            <div className="container">
              <a
                href="/landing"
                className="navbar-brand d-flex align-items-center"
              >
                <img
                  src={
                    "https://tabula.psgarx.com/static/media/RxConnexion.80ee8ffc.png"
                  }
                  alt="Logo"
                  width={300}
                />
              </a>
              <button
                type="button"
                className="btn btn-dark me-2 right"
                onClick={() => this.props.logoutUser()}
              >
                Logout ({this.props.auth.user.name})
              </button>
            </div>
          </div>
        </header>
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <main
          style={{ paddingLeft: "0", minHeight: "calc(-195px + 100vh)" }}
          className="choose-plan-initial container"
        >
          <section className="text-center container">
            <div className="row py-lg-3">
              <button
                type="button"
                className="btn btn-outline-primary float-left"
                onClick={() => window.history.back()}
              >
                Back
              </button>
              <div className="col-lg-9 col-md-8 mx-auto">
                <h3 className="fw-light choosePlan">
                  CHOOSE YOUR PERFECT PLAN TO GET STARTED TODAY!
                </h3>
              </div>
            </div>
          </section>
          <div className="row  mb-3 text-left">
            {solutions
              // .filter((each) => each.name !== "CostPlus")
              .map((each, i) => {
                return (
                  <div
                    className={`${
                      each.type === "plan" ? "col-md-4 gold-border" : "col-md-3"
                    } my-2 flip-card`}
                    key={i}
                  >
                    <div
                      className="card"
                      style={{
                        borderColor: each.borderColor
                          ? each.borderColor
                          : "#a72a96",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          backgroundImage: this.getBackground(each),
                          textAlign: "center",
                          paddingTop: "16px",
                        }}
                      >
                        <img src={each.image} width={each.width} />
                      </div>
                      <div className="content">
                        <div className="row  mb-3 " style={{ width: "100%" }}>
                          <div className="col-md-2 right">
                            <img src={rxl} width={40} />
                          </div>
                          <div
                            className="col-md-10 left"
                            style={{ paddingTop: "5px" }}
                          >
                            {each.type === "plan" ? (
                              <>
                                <b>{each.name}</b>
                                <img src={each.saveImg} className="save20" />
                              </>
                            ) : (
                              each.name
                            )}
                            <br />
                            {each.name === "Marketing" ? (
                              <a href="https://psgaonline.com/" target="_blank">
                                Visit Website
                              </a>
                            ) : each.name === "Marketing Plus" ? (
                              <a
                                href="https://login.rxconnexion.com/public/store/JQ5X/default"
                                target="_blank"
                              >
                                View Plans
                              </a>
                            ) : (
                              <>${each.price} per month</>
                            )}
                          </div>
                        </div>
                        <div className="additional-text p-4">
                          {each.content}
                          <br></br>
                          {each.name === "Marketing" ||
                          each.name === "Marketing Plus" ? (
                            <></>
                          ) : (
                            <>
                              {" "}
                              {this.isSubscribed(each) ? (
                                <div style={{ color: "gray" }}>
                                  Already Subscribed
                                </div>
                              ) : (
                                <div
                                  onClick={() => this.linkTo(each)}
                                  style={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    color: "#2a3e90",
                                  }}
                                >
                                  Subscribe Now
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </main>

        <TermCondition
          userInfo={this.state.userInfo}
          solutions={this.state.termArr}
          navigateTo={this.navigateTo}
        />
        <div className="modal fade" id="rxpodcast-plan-modal" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header" style={{ display: "block" }}>
                <div style={{ float: "left" }}>
                  <img
                    height="60"
                    alt="logo"
                    src={
                      "https://d3l9g5ay50k79j.cloudfront.net/logo/RxPodcast.png"
                    }
                  />
                </div>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h3 className="modal-title center" style={{ clear: "both" }}>
                  Select Plan
                </h3>
              </div>
              <div className="modal-body center">
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Basic.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Basic")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Plus.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Plus")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="center">
                        <img
                          src={
                            "https://d3l9g5ay50k79j.cloudfront.net/rxpodcast/RxPodcast_Pro.png"
                          }
                          alt="Plan"
                          className="pointer"
                          onClick={() => this.choosePlanRxPodcast("Pro")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => $("#rxpodcast-plan-modal").modal("hide")}
                  type="button"
                  className="btn btn-success btn-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(ChoosePlan);
